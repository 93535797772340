import React, { useState, useEffect, useMemo } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import CustomSidebar from './CustomSidebar';
import Table from './Table';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { pca, getAccessToken } from './auth';

// Define columns for both Members and Crisis
const memberColumns = [
  { label: "First Name", accessor: "First", sortable: true },
  { label: "Last Name", accessor: "Last", sortable: true },
  { label: "Email", accessor: "Email", sortable: true, sortbyOrder: "desc" },
  { label: "Phone", accessor: "Phone", sortable: true },
  { label: "Membership (FP)", accessor: "MembershipINT", sortable: true },
  { label: "Membership (Client)", accessor: "MembershipEXT", sortable: true },
  { label: "Parent company", accessor: "Parent", sortable: true },
  { label: "Start", accessor: "Start", sortable: true },
  { label: "End", accessor: "End", sortable: true },
  { label: "Division", accessor: "Company", sortable: true },
  { label: "Underwriter", accessor: "Underwriter", sortable: true },
  { label: "Service", accessor: "Type", sortable: true },
  { 
    label: "Status", 
    accessor: "status", 
    sortable: true,
    render: (record) => {
      const today = new Date();
      const startDate = record.Start ? new Date(record.Start) : null;
      const endDate = record.End ? new Date(record.End) : null;

      const isActive = startDate && endDate && 
        today >= startDate && 
        today <= endDate;

      return (
        <span style={{ 
          color: isActive ? '#4caf50' : '#f44336',
          fontWeight: 500
        }}>
          {isActive ? 'Active' : 'Inactive'}
        </span>
      );
    }
  }
];

const crisisColumns = [
  { label: "ID", accessor: "Id", sortable: true, width: "80px" },
  { 
    label: "DTG", 
    accessor: "DTG", 
    sortable: true,
    width: "160px",
    style: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  { 
    label: "Narrative", 
    accessor: "Narrative", 
    sortable: true,
    style: {
      minWidth: "400px"  // Give narrative column more space
    }
  },
  { 
    label: "Input By", 
    accessor: "InputBy", 
    sortable: true,
    width: "100px"
  }
];

// Move debounce outside component
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const GlobalSearchPage = ({ searchContext }) => {
  const {
    memberResults,
    setMemberResults,
    crisisResults,
    setCrisisResults,
    searchTerm,
    setSearchTerm,
    searchType,
    setSearchType,
    memberTotalRecords,
    setMemberTotalRecords,
    crisisTotalRecords,
    setCrisisTotalRecords
  } = searchContext;

  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(!memberResults && !crisisResults);
  const [offset, setOffset] = useState(0);
  const scrollContainerRef = React.useRef(null);
  const limit = 100;

  const { accounts } = useMsal();
  const navigate = useNavigate();

  const handleSearch = async (customOffset = null) => {
    setIsLoading(true);
    const accessToken = await getAccessToken();
    
    if (accessToken) {
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${accessToken}`);
      
      const currentOffset = customOffset !== null ? customOffset : offset;
      
      const endpoint = searchType === 'members' 
        ? `https://fp-api.focuspointintl.com/api/data/?searchpass=${searchTerm}`
        : `https://fp-api.focuspointintl.com/api/crisis?search=${searchTerm}&limit=${limit}&offset=${currentOffset}`;

      try {
        const response = await fetch(endpoint, { headers });
        const data = await response.json();

        if (searchType === 'members') {
          setMemberResults(data);
          setMemberTotalRecords(data.length);
        } else {
          // Handle crisis response format
          if (data.status === 'success') {
            if (currentOffset === 0) {
              // For new searches, replace the results
              setCrisisResults(data.results);
            } else {
              // For pagination, append to existing results
              setCrisisResults(prev => {
                // Make sure we have previous results
                if (!prev) return data.results;
                // Append new results
                return [...prev, ...data.results];
              });
            }
            setCrisisTotalRecords(parseInt(data.total_records));
          } else {
            // Handle error response
            setCrisisResults(null);
            setCrisisTotalRecords(0);
          }
        }
        setShowMessage(false);
      } catch (error) {
        console.error('API Error:', error);
        if (searchType === 'members') {
          setMemberResults(null);
          setMemberTotalRecords(0);
        } else {
          setCrisisResults(null);
          setCrisisTotalRecords(0);
        }
      }
      setIsLoading(false);
    } else {
      console.error('Access token retrieval/refresh failed');
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setOffset(0);
      handleSearch(0); // Force offset to 0 for new search
    }
  };

  const handleSearchClick = () => {
    setOffset(0);
    handleSearch(0); // Force offset to 0 for new search
  };

  const handleSearchTypeChange = (event, newType) => {
    if (newType !== null) {
      setSearchType(newType);
      setOffset(0);
      setShowMessage(newType === 'members' ? !memberResults : !crisisResults);
    }
  };

  // Get the current results and total records based on search type
  const apiResult = useMemo(() => {
    return searchType === 'members' ? memberResults : crisisResults;
  }, [searchType, memberResults, crisisResults]);

  const totalRecords = useMemo(() => {
    return searchType === 'members' ? memberTotalRecords : crisisTotalRecords;
  }, [searchType, memberTotalRecords, crisisTotalRecords]);

  const handleScroll = React.useCallback(() => {
    if (!scrollContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
    const scrolledToBottom = Math.abs(scrollHeight - scrollTop - clientHeight) <= 1;

    if (
      searchType === 'crisis' &&
      !isLoading &&
      scrolledToBottom &&
      apiResult &&
      apiResult.length < totalRecords
    ) {
      const newOffset = offset + limit;
      console.log('Loading more results...', {
        current: apiResult.length,
        total: totalRecords,
        offset: newOffset
      });
      setOffset(newOffset);
      handleSearch(newOffset);
    }
  }, [searchType, isLoading, apiResult, totalRecords, offset, limit]);

  // Add debounced scroll handler
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const debouncedHandler = debounce(handleScroll, 100);
    
    console.log('Setting up scroll listener');
    scrollContainer.addEventListener('scroll', debouncedHandler);
    
    return () => {
      console.log('Cleaning up scroll listener');
      scrollContainer.removeEventListener('scroll', debouncedHandler);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (accounts.length === 0) {
      navigate('/');
    }
  }, [accounts, navigate]);

  const columns = searchType === 'members' ? memberColumns : crisisColumns;

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      height: 'calc(100vh - 64px)',
      overflow: 'hidden',
      position: 'relative'
    }}>
      <div className="content-header" style={{ 
        padding: '20px',
        backgroundColor: 'white',
        borderBottom: '1px solid #eee',
        flexShrink: 0
      }}>
        <h1 style={{ margin: '0 0 20px 0' }}>Global Search Page</h1>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '1rem' }}>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <TextField
              label="Search"
              value={searchTerm}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              variant="outlined"
              style={{ width: '300px' }}
            />
            <Button 
              variant="contained" 
              onClick={handleSearchClick}
            >
              Search
            </Button>
          </div>
          <ToggleButtonGroup
            value={searchType}
            exclusive
            onChange={handleSearchTypeChange}
            aria-label="search type"
            size="small"
          >
            <ToggleButton value="members">Members</ToggleButton>
            <ToggleButton value="crisis">Crisis</ToggleButton>
          </ToggleButtonGroup>
        </div>
        {apiResult && apiResult.length > 0 && (
          <div className="results-caption" style={{ marginBottom: 0 }}>
            Calculon has found the information that you seek: {totalRecords} results
            {searchType === 'crisis' && apiResult.length < totalRecords && (
              <span style={{ marginLeft: '0.5rem', color: '#666' }}>
                (Showing {apiResult.length} of {totalRecords})
              </span>
            )}
          </div>
        )}
      </div>

      <div 
        ref={scrollContainerRef}
        style={{ 
          flex: 1,
          minHeight: 0,
          overflow: 'auto',
          position: 'relative'
        }}
        onScroll={(e) => {
          const target = e.target;
          const bottom = Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) <= 1;
          
          console.log('Direct scroll event', {
            scrollTop: target.scrollTop,
            scrollHeight: target.scrollHeight,
            clientHeight: target.clientHeight,
            bottom,
            searchType,
            isLoading,
            hasMore: apiResult && apiResult.length < totalRecords
          });

          if (bottom && searchType === 'crisis' && !isLoading && apiResult && apiResult.length < totalRecords) {
            const newOffset = offset + limit;
            console.log('Triggering load more...', { newOffset });
            setOffset(newOffset);
            handleSearch(newOffset);
          }
        }}
      >
        {isLoading && offset === 0 ? (
          <p style={{ padding: '20px', margin: 0 }}>Loading...</p>
        ) : (
          <>
            {apiResult !== null ? (
              apiResult.length > 0 ? (
                <div style={{ position: 'relative' }}>
                  <Table 
                    data={apiResult} 
                    columns={columns}
                    stickyHeader={true}
                  />
                  {searchType === 'crisis' && apiResult.length < totalRecords && !isLoading && (
                    <div style={{ 
                      padding: '1rem', 
                      textAlign: 'center',
                      color: '#666',
                      borderTop: '1px solid #eee'
                    }}>
                      Scroll to load more results...
                    </div>
                  )}
                </div>
              ) : (
                <p style={{ padding: '20px', margin: 0 }}>No results found.</p>
              )
            ) : (
              <>
                {showMessage && (
                  <p style={{ padding: '20px', margin: 0 }}>
                    <b><i>Instructions</i></b><br /><br />
                    When searching for a customer, please remember that we have different types of accounts with different structures...
                  </p>
                )}
              </>
            )}
          </>
        )}
        {isLoading && offset > 0 && (
          <div style={{ 
            padding: '1rem', 
            textAlign: 'center',
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderTop: '1px solid #eee'
          }}>
            Loading more results...
          </div>
        )}
      </div>
    </div>
  );
};

export default GlobalSearchPage;
