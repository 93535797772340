import React, { useEffect, useState } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { pca, login, checkAuthentication } from './auth';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SignInSide from './SignInSide';
import GlobalSearchPage from './GlobalSearchPage';
import WorldMapPage from './WorldMapPage';
import CustomSidebar from './CustomSidebar';
import './App.css';

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  
  // Add global state for search results
  const [memberResults, setMemberResults] = useState(null);
  const [crisisResults, setCrisisResults] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('members');
  const [memberTotalRecords, setMemberTotalRecords] = useState(0);
  const [crisisTotalRecords, setCrisisTotalRecords] = useState(0);

  useEffect(() => {
    const handleAuthentication = async () => {
      const isAuthenticated = await checkAuthentication();
      setAuthenticated(isAuthenticated);      
    };

    handleAuthentication();
  }, []);

  const handleSignIn = () => {
    if (!authenticated) {
      login();
    }
  };

  const handleSidebarCollapse = (collapsed) => {
    setSidebarCollapsed(collapsed);
  };

  // Create a search context object with all the search-related state and setters
  const searchContext = {
    memberResults,
    setMemberResults,
    crisisResults,
    setCrisisResults,
    searchTerm,
    setSearchTerm,
    searchType,
    setSearchType,
    memberTotalRecords,
    setMemberTotalRecords,
    crisisTotalRecords,
    setCrisisTotalRecords
  };

  return (
    <MsalProvider instance={pca}>
      <Router>
        {authenticated ? (
          <div className={`layout ${sidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
            <CustomSidebar onCollapse={handleSidebarCollapse} />
            <main className="main-content">
              <Routes>
                <Route path="/" element={<Navigate to="/global-search" replace={true} />} />
                <Route 
                  path="/global-search" 
                  element={<GlobalSearchPage searchContext={searchContext} />} 
                />
                <Route 
                  path="/world-map" 
                  element={<WorldMapPage searchContext={searchContext} />} 
                />
              </Routes>
            </main>
          </div>
        ) : (
          <Routes>
            <Route path="*" element={<SignInSide handleSignIn={handleSignIn} setAuthenticated={setAuthenticated} />} />
          </Routes>
        )}
      </Router>
    </MsalProvider>
  );
}

export default App;
