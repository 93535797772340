import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiSearch, FiLogOut, FiBookOpen, FiMap, FiChevronDown, FiChevronRight, FiChevronLeft, FiChevronRight as FiCollapse } from 'react-icons/fi';
import { logout } from './auth';

const CustomSidebar = ({ onCollapse }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDocsOpen, setIsDocsOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const isActive = (path) => location.pathname === path;

  const menuItems = [
    { icon: <FiSearch />, text: 'Global Search', path: '/global-search' },
    { icon: <FiMap />, text: 'World Map', path: '/world-map' }
  ];

  const handleLogout = () => {
    logout();
  };

  const toggleCollapse = () => {
    const newCollapsed = !isCollapsed;
    setIsCollapsed(newCollapsed);
    onCollapse?.(newCollapsed);
  };

  return (
    <nav className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-header">
        {isCollapsed ? (
          <div className="collapsed-title">OxR | CAP</div>
        ) : (
          <img
            src="https://www.focuspointintl.com/images/logo-cap-or-White.png"
            alt="Logo"
            className="sidebar-logo"
          />
        )}
      </div>

      <button className="collapse-button" onClick={toggleCollapse}>
        {isCollapsed ? <FiChevronRight /> : <FiChevronLeft />}
      </button>

      <div className="sidebar-nav">
        {menuItems.map((item) => (
          <button
            key={item.path}
            className={'nav-item ' + (isActive(item.path) ? 'active' : '')}
            onClick={() => navigate(item.path)}
            title={isCollapsed ? item.text : ''}
          >
            <span className="nav-icon">{item.icon}</span>
            {!isCollapsed && <span className="nav-text">{item.text}</span>}
          </button>
        ))}

        <div className="submenu-container">
          <button
            className={'nav-item ' + (isDocsOpen ? 'active' : '')}
            onClick={() => setIsDocsOpen(!isDocsOpen)}
            title={isCollapsed ? 'Documentation' : ''}
          >
            <span className="nav-icon"><FiBookOpen /></span>
            {!isCollapsed && (
              <>
                <span className="nav-text">Documentation</span>
                <span className="nav-chevron">
                  {isDocsOpen ? <FiChevronDown /> : <FiChevronRight />}
                </span>
              </>
            )}
          </button>
          
          {isDocsOpen && !isCollapsed && (
            <div className="submenu">
              <button className="nav-item submenu-item" onClick={() => navigate('/docs/intro')}>
                <span className="nav-text">Introduction</span>
              </button>
              <button className="nav-item submenu-item" onClick={() => navigate('/docs/getting-started')}>
                <span className="nav-text">Getting Started</span>
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="sidebar-footer">
        <button 
          className="nav-item" 
          onClick={handleLogout}
          title={isCollapsed ? 'Logout' : ''}
        >
          <span className="nav-icon"><FiLogOut /></span>
          {!isCollapsed && <span className="nav-text">Logout</span>}
        </button>
      </div>
    </nav>
  );
};

export default CustomSidebar;
