import React, { useState } from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';

const Table = ({ data, columns, stickyHeader }) => {
  const [expandedGroups, setExpandedGroups] = useState(new Set());
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const isRecordActive = (record) => {
    const today = new Date();
    const startDate = record.Start ? new Date(record.Start) : null;
    const endDate = record.End ? new Date(record.End) : null;
    return startDate && endDate && today >= startDate && today <= endDate;
  };

  const handleSortingChange = (accessor) => {
    const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
  };

  // Generate a unique key for a record
  const getRecordKey = (record, index, isChild = false) => {
    const baseKey = record.Email || record.MembershipEXT || `row-${index}-${record.First || ''}-${record.Last || ''}`;
    return isChild ? `child-${baseKey}-${index}` : baseKey;
  };

  const processData = (data) => {
    if (!data) return [];

    const processedEmails = new Set();
    const processedMemberships = new Set();
    const result = [];

    // Process all records in order
    data.forEach(record => {
      // Add status field to each record for sorting
      const recordWithStatus = {
        ...record,
        status: isRecordActive(record) ? 1 : 0  // Use numbers for easier sorting
      };

      // Check if this record should be grouped
      const shouldGroup = record.Email && 
        ((record.Parent === 'GlobalStar') || 
         (record.Type === 'CAP' && record.Parent !== 'Medjet'));

      if (shouldGroup) {
        if (record.Parent === 'GlobalStar') {
          // For GlobalStar, check if we've processed this MembershipEXT
          if (processedMemberships.has(record.MembershipEXT)) {
            return;
          }

          // Find all records with the same MembershipEXT
          const groupRecords = data.filter(r => r.MembershipEXT === record.MembershipEXT)
            .map(r => ({
              ...r,
              status: isRecordActive(r) ? 1 : 0
            }));

          if (groupRecords.length > 1) {
            // Sort children by End date descending
            const sortedChildren = [...groupRecords].sort((a, b) => {
              const dateA = a.End ? new Date(a.End) : new Date(0);
              const dateB = b.End ? new Date(b.End) : new Date(0);
              return dateB - dateA;
            });

            // Add as parent with children
            result.push({
              ...sortedChildren[0],
              isParent: true,
              children: sortedChildren.slice(1),
              groupKey: record.MembershipEXT
            });

            // Mark this MembershipEXT as processed
            processedMemberships.add(record.MembershipEXT);
          } else {
            // Single record, add directly
            result.push({ ...recordWithStatus, isParent: false });
            processedMemberships.add(record.MembershipEXT);
          }
        } else {
          // For non-GlobalStar records, check if we've processed this email
          if (processedEmails.has(record.Email)) {
            return;
          }

          // Find all records with the same email
          const groupRecords = data.filter(r => r.Email === record.Email)
            .map(r => ({
              ...r,
              status: isRecordActive(r) ? 1 : 0
            }));

          if (groupRecords.length > 1) {
            // Sort children by End date descending
            const sortedChildren = [...groupRecords].sort((a, b) => {
              const dateA = a.End ? new Date(a.End) : new Date(0);
              const dateB = b.End ? new Date(b.End) : new Date(0);
              return dateB - dateA;
            });

            // Add as parent with children
            result.push({
              ...sortedChildren[0],
              isParent: true,
              children: sortedChildren.slice(1),
              groupKey: record.Email
            });

            // Mark this email as processed
            processedEmails.add(record.Email);
          } else {
            // Single record, add directly
            result.push({ ...recordWithStatus, isParent: false });
            processedEmails.add(record.Email);
          }
        }
      } else {
        // Not a group record, add directly
        result.push({ ...recordWithStatus, isParent: false });
      }
    });

    return sortData(result);
  };

  const sortData = (records) => {
    if (!sortField) return records;

    return [...records].sort((a, b) => {
      if (a[sortField] === null) return 1;
      if (b[sortField] === null) return -1;
      if (a[sortField] === null && b[sortField] === null) return 0;
      return (
        a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
          numeric: true,
        }) * (order === "asc" ? 1 : -1)
      );
    });
  };

  const toggleGroup = (groupKey) => {
    const newExpanded = new Set(expandedGroups);
    if (newExpanded.has(groupKey)) {
      newExpanded.delete(groupKey);
    } else {
      newExpanded.add(groupKey);
    }
    setExpandedGroups(newExpanded);
  };

  const processedData = processData(data);

  const renderRow = (record, isChild = false, index) => {
    const active = isRecordActive(record);
    const rowStyle = {
      backgroundColor: isChild ? '#f8f9fa' : (index % 2 === 0 ? '#fff' : '#f9f9f9'),
      opacity: active ? 1 : 0.7,
      transition: 'opacity 0.2s ease',
      color: active ? 'inherit' : '#666'
    };

    return (
      <tr style={rowStyle}>
        <td style={{ 
          padding: '12px',
          textAlign: 'center',
          borderBottom: '1px solid #eee',
          width: '40px'
        }}>
          {record.isParent && (
            <button
              onClick={() => toggleGroup(record.groupKey)}
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                padding: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                opacity: 1
              }}
            >
              {expandedGroups.has(record.groupKey) ? <FiMinus /> : <FiPlus />}
            </button>
          )}
        </td>
        {columns.map(({ accessor, render, width, style = {} }) => {
          const cell = render ? render(record) : record[accessor];
          return (
            <td 
              key={accessor}
              style={{
                padding: '12px',
                borderBottom: '1px solid #eee',
                paddingLeft: isChild && accessor === columns[0].accessor ? '40px' : '12px',
                width: width || 'auto',
                ...style
              }}
            >
              {cell}
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <div style={{ 
      width: '100%',
      position: 'relative',
      backgroundColor: 'white'
    }}>
      <table style={{ 
        width: '100%',
        borderCollapse: 'collapse'
      }}>
        <thead style={stickyHeader ? {
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1,
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        } : {}}>
          <tr>
            <th style={{ width: '40px', padding: '12px' }}></th>
            {columns.map(({ label, accessor, sortable, width, style = {} }) => {
              const cl = sortable
                ? sortField === accessor && order === "asc"
                  ? "up"
                  : sortField === accessor && order === "desc"
                  ? "down"
                  : "default"
                : "";
              return (
                <th
                  key={accessor}
                  onClick={sortable ? () => handleSortingChange(accessor) : null}
                  style={{
                    cursor: sortable ? 'pointer' : 'default',
                    padding: '12px',
                    textAlign: 'left',
                    backgroundColor: 'white',
                    borderBottom: '2px solid #eee',
                    position: 'relative',
                    width: width || 'auto',
                    ...style
                  }}
                >
                  {label}
                  {sortable && (
                    <span style={{ marginLeft: '8px', opacity: 0.5 }}>
                      {cl === 'up' ? '↑' : cl === 'down' ? '↓' : '↕'}
                    </span>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {processedData.map((record, index) => (
            <React.Fragment key={getRecordKey(record, index)}>
              {renderRow(record, false, index)}
              {record.isParent && expandedGroups.has(record.groupKey) && 
                record.children.map((child, childIndex) => renderRow(child, true, childIndex))
              }
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table; 