import React, { useState, useEffect, useMemo } from 'react';
import { MapContainer, TileLayer, CircleMarker, Popup, Marker, ZoomControl, LayersControl, useMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet.heat';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { renderToStaticMarkup } from 'react-dom/server';
import { useMsal } from '@azure/msal-react';
import { getAccessToken } from './auth';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Paper, Typography, Box, Chip, ToggleButton, ToggleButtonGroup, 
  FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { format } from 'date-fns';

// Mapbox styles
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
const mapStyles = {
  default: {
    url: `https://api.mapbox.com/styles/v1/mapbox/light-v11/tiles/{z}/{x}/{y}?access_token=${MAPBOX_TOKEN}`,
    attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a>'
  },
  satellite: {
    url: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/{z}/{x}/{y}?access_token=${MAPBOX_TOKEN}`,
    attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a>'
  },
  dark: {
    url: `https://api.mapbox.com/styles/v1/mapbox/dark-v11/tiles/{z}/{x}/{y}?access_token=${MAPBOX_TOKEN}`,
    attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a>'
  }
};

const worldBounds = new L.LatLngBounds(
  new L.LatLng(-88, -180),
  new L.LatLng(88, 180)
);

const customIcon = new L.divIcon({
  html: renderToStaticMarkup(<ApartmentIcon fontSize="medium" style={{ color: '#1a237e' }} />),
  className: 'custom-marker-icon',
  iconSize: [32, 32],
  iconAnchor: [16, 32],
});

const locations = {
  hq: { 
    position: { lat: 26.1086525, lng: -80.2609162 },
    name: 'FocusPoint HQ',
    location: 'Fort Lauderdale, FL',
    type: 'headquarters'
  },
  crcsa: { 
    position: { lat: -33.9761999, lng: 18.4624045 },
    name: 'FocusPoint CRCSA',
    location: 'Cape Town, South Africa',
    type: 'office'
  }
};

// Move incidentTypeColors outside component to persist between renders
const incidentTypeColors = {};

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// HeatmapLayer component
const HeatmapLayer = ({ data }) => {
  const map = useMap();

  useEffect(() => {
    if (!data || !data.length) return;

    // Create a map of locations to count occurrences
    const locationCounts = new Map();
    data.forEach(record => {
      const key = `${record.gpsLat},${record.gpsLong}`;
      locationCounts.set(key, (locationCounts.get(key) || 0) + 1);
    });

    // Find the maximum count for normalization
    const maxCount = Math.max(...locationCounts.values());

    // Create points with normalized intensity
    const points = data.map(record => {
      const key = `${record.gpsLat},${record.gpsLong}`;
      const count = locationCounts.get(key);
      return [
        record.gpsLat,
        record.gpsLong,
        count / maxCount  // Normalize intensity between 0 and 1
      ];
    });

    const heatLayer = L.heatLayer(points, {
      radius: 35,
      blur: 20,
      maxZoom: 10,
      gradient: { 0.1: 'blue', 0.2: 'lime', 0.3: 'yellow', 0.4: 'red' }
    });

    heatLayer.addTo(map);
    return () => {
      map.removeLayer(heatLayer);
    };
  }, [map, data]);

  return null;
};

// Add this component after the HeatmapLayer component
const HeatmapLegend = () => {
  return (
    <Paper
      sx={{
        position: 'absolute',
        bottom: 20,
        right: 20,
        zIndex: 1000,
        padding: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: 1,
        boxShadow: 1,
        minWidth: 200,
      }}
    >
      <Typography variant="subtitle2" gutterBottom>
        Incident Density
      </Typography>
      <Box
        sx={{
          height: 20,
          width: '100%',
          background: 'linear-gradient(to right, blue, lime, yellow, red)',
          borderRadius: 1,
          mb: 1,
        }}
      />
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        fontSize: '0.75rem',
        color: 'text.secondary'
      }}>
        <span>Low</span>
        <span>Medium</span>
        <span>High</span>
      </Box>
    </Paper>
  );
};

const WorldMapPage = ({ searchContext }) => {
  // Keep the search context but don't modify it
  const { searchType, setSearchType } = searchContext;

  // Keep local state for map-specific functionality
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState(new Set());
  const [mapStyle, setMapStyle] = useState('default');
  const [viewMode, setViewMode] = useState('markers');
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [mapData, setMapData] = useState(null);
  const { accounts } = useMsal();
  const navigate = useNavigate();

  const handleSearch = async (showLoading = true) => {
    if (showLoading) {
      setLoading(true);
    }
    setError(null);
    
    try {
      const accessToken = await getAccessToken();
      
      if (!accessToken) {
        throw new Error('Authentication failed');
      }

      const headers = new Headers();
      headers.append('Authorization', `Bearer ${accessToken}`);
      
      const response = await fetch('https://fp-api.focuspointintl.com/api/casedata', { headers });
      if (!response.ok) throw new Error('Failed to fetch data');
      
      const data = await response.json();

      // Assign colors to new incident types and clean up unused ones
      const currentTypes = new Set(data.map(record => record.incidenttype));
      
      // Assign colors to new types
      currentTypes.forEach(type => {
        if (!incidentTypeColors[type]) {
          incidentTypeColors[type] = getRandomColor();
        }
      });

      // Clean up selected types that no longer exist
      setSelectedTypes(prev => {
        const newSelected = new Set(prev);
        for (const type of prev) {
          if (!currentTypes.has(type)) {
            newSelected.delete(type);
          }
        }
        return newSelected;
      });

      setMapData(data);
      setLastUpdated(new Date());
    } catch (err) {
      setError(err.message);
      console.error('API Error:', err);
    } finally {
      if (showLoading) {
        setLoading(false);
      }
    }
  };

  // Initial load
  useEffect(() => {
    if (accounts.length === 0) {
      navigate('/');
    } else {
      handleSearch();
    }
  }, [accounts, navigate]);

  // Set up auto-refresh interval
  useEffect(() => {
    // Refresh every 10 minutes
    const intervalId = setInterval(() => {
      handleSearch(false); // Don't show loading state for auto-refresh
    }, 10 * 60 * 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array means this only runs once on mount

  const toggleIncidentType = (type) => {
    const newSelected = new Set(selectedTypes);
    if (newSelected.has(type)) {
      newSelected.delete(type);
    } else {
      newSelected.add(type);
    }
    setSelectedTypes(newSelected);
  };

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const filteredData = mapData
    ? mapData.filter(record => 
        selectedTypes.size === 0 || selectedTypes.has(record.incidenttype)
      )
    : [];

  const uniqueIncidentTypes = mapData 
    ? [...new Set(mapData.map(record => record.incidenttype))]
    : [];

  // Memoize the sorted incident types
  const sortedIncidentTypes = useMemo(() => {
    return uniqueIncidentTypes.sort((a, b) => a.localeCompare(b));
  }, [uniqueIncidentTypes]);

  return (
    <div className="h-full">
      <Paper elevation={2} className="p-4 mb-4">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h5" component="h3">
            Command Overview
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Typography variant="caption" color="textSecondary">
              Auto-refreshes every 10 minutes
            </Typography>
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={handleViewModeChange}
              size="small"
            >
              <ToggleButton value="markers">Markers</ToggleButton>
              <ToggleButton value="heatmap">Heatmap</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        
        <Box sx={{ mb: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          {uniqueIncidentTypes.map(type => (
            <Chip
              key={type}
              label={`${type} (${filteredData.filter(r => r.incidenttype === type).length})`}
              onClick={() => toggleIncidentType(type)}
              sx={{
                backgroundColor: incidentTypeColors[type],
                color: 'white',
                opacity: selectedTypes.size === 0 || selectedTypes.has(type) ? 1 : 0.5,
                '&:hover': {
                  backgroundColor: incidentTypeColors[type],
                  opacity: 0.8,
                },
                transition: 'opacity 0.2s ease',
              }}
            />
          ))}
        </Box>

        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <Typography>
            Total Incidents: {filteredData?.length || 0}
          </Typography>
          <Typography>
            Active Types: {selectedTypes.size || 'All'}
          </Typography>
          <Typography>
            Last Updated: {format(lastUpdated, 'MMM d, yyyy HH:mm:ss')}
          </Typography>
        </Box>
      </Paper>

      <div style={{ height: 'calc(100vh - 200px)', position: 'relative' }}>
        {loading && (
          <Box sx={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)',
            zIndex: 1000 
          }}>
            <CircularProgress />
          </Box>
        )}

        {error && (
          <Box sx={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)',
            color: 'error.main',
            zIndex: 1000 
          }}>
            <Typography color="error">{error}</Typography>
          </Box>
        )}

        <MapContainer
          center={[0, 0]}
          zoom={2}
          style={{ width: '100%', height: '100%' }}
          maxBounds={worldBounds}
          maxBoundsViscosity={1}
          minZoom={2}
          zoomControl={false}
        >
          <ZoomControl position="topright" />
          
          <LayersControl position="topright">
            {Object.entries(mapStyles).map(([styleName, style]) => (
              <LayersControl.BaseLayer 
                key={styleName}
                checked={mapStyle === styleName}
                name={styleName.charAt(0).toUpperCase() + styleName.slice(1)}
              >
                <TileLayer
                  url={style.url}
                  attribution={style.attribution}
                />
              </LayersControl.BaseLayer>
            ))}
          </LayersControl>

          {/* Office locations */}
          {Object.values(locations).map(location => (
            <Marker 
              key={location.name}
              position={location.position} 
              icon={customIcon}
            >
              <Popup>
                <Typography variant="subtitle2">{location.name}</Typography>
                <Typography variant="body2">{location.location}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {location.type.charAt(0).toUpperCase() + location.type.slice(1)}
                </Typography>
              </Popup>
            </Marker>
          ))}

          {/* Incident visualization based on view mode */}
          {!loading && mapData && (
            viewMode === 'markers' ? (
              <MarkerClusterGroup>
                {filteredData.map((record) => (
                  <CircleMarker
                    key={record.id}
                    center={[record.gpsLat, record.gpsLong]}
                    radius={8}
                    fillColor={incidentTypeColors[record.incidenttype]}
                    color={incidentTypeColors[record.incidenttype]}
                    weight={1}
                    opacity={1}
                    fillOpacity={0.7}
                  >
                    <Popup>
                      <Typography variant="subtitle2">{record.customerName}</Typography>
                      <Typography variant="body2">Device: {record.deviceId}</Typography>
                      <Typography variant="body2">Type: {record.incidenttype}</Typography>
                      <Typography variant="body2">Status: Active</Typography>
                      {record.timestamp && (
                        <Typography variant="caption" display="block" color="textSecondary">
                          {format(new Date(record.timestamp), 'MMM d, yyyy HH:mm:ss')}
                        </Typography>
                      )}
                    </Popup>
                  </CircleMarker>
                ))}
              </MarkerClusterGroup>
            ) : (
              <HeatmapLayer data={filteredData} />
            )
          )}
        </MapContainer>
        {viewMode === 'heatmap' && <HeatmapLegend />}
      </div>
    </div>
  );
};

export default WorldMapPage;
